<template>
  <div id="common">
    <div class="page" size="A4" v-for="page in pages" :key="'page' + page">
      <v-row class="pt-4">
        <v-col cols="1">
          <v-avatar size="45">
            <img
              src="https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>
        </v-col>
        <v-col>
          <b style="font-size: 15px">
            {{ purchase_order.company.name }}
            - {{ $format.cnpj(purchase_order.company.cnpj) }}
          </b>
          <template v-if="hasAddress">
            <div>
              {{ address.address }},{{ address.number }},{{ address.district }},
              {{ address.city }} - {{ address.state }},
              {{ $format.cep(address.postal_code) }}
            </div>
          </template>
          <div v-if="hasPhones" class="d-flex flex-row">
            <span class="mr-2">Tel:</span>
            <div v-for="(phone, index) in phones" :key="index">
              {{ phone.contact }}
              <span class="mr-1" v-if="phones.length > index + 1">,</span>
            </div>
          </div>
        </v-col>

        <v-col cols="1" class="text-end"> Pg{{ page }}/{{ pages }} </v-col>
      </v-row>

      <hr class="mt-2 mb-4" />
      <v-row>
        <v-col class="text-center pa-2" style="font-size: 13px">
          Pedidos de Mercadorias
        </v-col>
      </v-row>
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="7">
          <span> <b>Fornecedor:</b> {{ purchase_order.supplier.name }} </span>
          <br />
          <span> <b> Numero do pedido: </b> {{ purchase_order.code }} </span>
        </v-col>
        <v-col cols="4">
          <div>
            <b>Data de emissão: </b>
            {{ $format.dateTimeBr(purchase_order.created_at) }}
            <br />
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-1" style="word-break: break-word" v-if="page == 1">
        <v-col>
          <br />
          <span>Observação: {{ purchase_order.notes }}</span>
        </v-col>
      </v-row>

      <v-data-table
        :page="page"
        :headers="headers"
        :items="getItems(page)"
        :items-per-page='-1'
        hide-default-footer
        disable-sort
        dense
      >
        <template v-slot:[`item.product`]="{ item }">
          <span v-if="item.product">
            {{ item.product.name }}
          </span>

          <span v-else>
            <span>{{ item.product_name }}</span>
          </span>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          <span v-if="item.id">{{ $format.decimal(item.quantity) }}</span>
        </template>
        <template v-slot:[`item.product_total`]="{ item }">
          <span v-if="item.id">{{ $format.decimal(item.product_total) }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itensPerPage: 55,
      purchase_order: {
        purchase_order_products: [],
      },
      headers: [
        { text: "Produto", value: "product", align: "start", width: "80%" },
        { text: "Quantidade", value: "quantity", align: "center" },
        { text: "Total. Prod", value: "product_total", align: "center" },
      ],
    };
  },

  async created() {
    this.$loading.start();

    await this.$http
      .show("purchase-order/purchase-order", this.$route.params.id)
      .then((response) => {
        this.purchase_order = response.purchase_order;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    hasCustomer() {
      return this.purchase_order.customer_id;
    },
    hasAddress() {
      return this.purchase_order.company.main_address;
    },

    address() {
      return this.purchase_order.company.main_address;
    },

    hasPhones() {
      return this.purchase_order.company.phones;
    },

    phones() {
      return this.purchase_order.company.phones;
    },

    personType() {
      return this.purchase_order.customer.person_type;
    },
    docLabel() {
      return this.personType == "juridica" ? "CNPJ:" : "CPF:";
    },
    docNumber() {
      return this.personType == "juridica"
        ? this.$format.cnpj(this.purchase_order.customer.cnpj)
        : this.$format.cpf(this.purchase_order.customer.cpf);
    },

    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    purchase_order_products() {
      return this.purchase_order.purchase_order_products;
    },
    itensCount() {
      if (this.purchase_order_products) {
        return this.purchase_order_products.length;
      }
      return 1;
    },
  },
  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },
    getNetPrice(product) {
      let netPrice =
        product.price - (product.price * product.discount_percent) / 100;

      return this.$format.decimal(netPrice);
    },

    getItems(page) {
      let items = this.purchase_order_products.slice(
        (page - 1) * this.itensPerPage,
        page * this.itensPerPage
      );
      // if (items.length < this.itensPerPage) {
      //   for (let i = items.length; i < this.itensPerPage; i++) {
      //     items.push({});
      //   }
      // }
      return items;
    },
  },
};
</script>
<style  lang="scss">
#common {
  * {
    font: 8pt "Tahoma";
    text-transform: uppercase;
  }

  .page {
    width: 21cm;
    height: 29.7cm;
    padding-top: 0.1cm;
    padding-left: 0.3cm;
    padding-right: 0.3cm;
    margin: 0.3cm;
    border: 1px #d3d3d3 solid;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    font: 12px "Tahoma";
  }

  div.col {
    padding-top: 0.4%;
    padding-bottom: 0.4%;
    font-size: 10px;
  }

  .v-data-table .v-data-table-header tr th {
    font-size: 12px !important;
    height: 16px !important;
    padding-left: 2pt !important;
    padding-right: 2pt !important;
    border: #d3d3d3 0.01px solid !important;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding-left: 2pt !important;
    padding-right: 2pt !important;
    font-size: 10px !important;
    height: 16px !important;

    border: #d3d3d3 0.01px solid !important;
  }

  @page {
    size: A4;
    margin: 0.5cm;
    padding: 0.5cm;
  }

  @media print {
    * {
      font: 8pt "Tahoma";
    }

    .page {
      display: block;
      margin: initial;
      border: rgba(0, 0, 0, 0.1);
      border-radius: initial;
      width: initial;
      height: 29.7cm;
      box-shadow: initial;
      background: initial;
      page-break-after: avoid;
      page-break-before: always;
      page-break-inside: avoid;
      size: landscape;
    }
  }
}
</style>